import commerce from "../lib/commerce";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import imageNotFound from "../assets/image-not-found.jpg";
const ProductsList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    const page = 2;
    // {
    //   page,
    // }
    commerce.products
      .list()
      .then((products) => {
        setProducts(products.data);
        console.log(products.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("There was an error fetching the products", error);
      });
  };

  return (
    <section className="mt-10 mb-20">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="md:text-4xl font-serif text-4xl font-semibold tracking-tight mb-24 text-center text-[#0a1b48]">
            Our Products
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {products.map((product, index) => (
              <Link to={"/product/" + product.id} key={index}>
                <div className="mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                  <div className="">
                    {product.conditionals.has_images ? (
                      <img
                        src={product.image?.url}
                        alt={product.name}
                        className="w-full aspect-square object-cover"
                        // width={product.image.image_dimensions.width}
                        // height={product.image.image_dimensions.height}
                      />
                    ) : (
                      <img
                        src={imageNotFound}
                        alt={product.name}
                        className="w-full aspect-square object-cover"
                        // width={product.image.image_dimensions.width}
                        // height={product.image.image_dimensions.height}
                      />
                    )}
                  </div>
                  <div className="mt-5">
                    <div className="flex items-center justify-between">
                      <h6 className="font-semibold text-sm md:text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">
                        {product.name}
                      </h6>
                      <h6 className="font-semibold text-sm md:text-lg leading-8 text-indigo-600">
                        {product.price.formatted_with_symbol}
                      </h6>
                    </div>
                    {/* <p className="mt-2 font-normal text-sm leading-6 text-gray-500">
                  Orange & Aloe Vera
                </p> */}
                  </div>
                </div>
              </Link>
            ))}
            {/* <a
            href="javascript:;"
            className="mx-auto sm:ml-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500"
          >
            <div className="">
              <img
                src="https://pagedone.io/asset/uploads/1700726174.png"
                alt="plastic bottle image"
                className="w-full aspect-square"
              />
            </div>
            <div className="mt-5">
              <div className="flex items-center justify-between">
                <h6 className="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">
                  Plstic bottle
                </h6>
                <h6 className="font-semibold text-xl leading-8 text-indigo-600">
                  $40
                </h6>
              </div>
              <p className="mt-2 font-normal text-sm leading-6 text-gray-500">
                Black color
              </p>
            </div>
          </a>

          <a
            href="javascript:;"
            className="mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500"
          >
            <div className="">
              <img
                src="https://pagedone.io/asset/uploads/1700726191.png"
                alt="cream image"
                className="w-full aspect-square"
              />
            </div>
            <div className="mt-5">
              <div className="flex items-center justify-between">
                <h6 className="font-semibold text-xl leading-8 text-black  transition-all duration-500 group-hover:text-indigo-600">
                  Men cream
                </h6>
                <h6 className="font-semibold text-xl leading-8 text-indigo-600">
                  $100
                </h6>
              </div>
              <p className="mt-2 font-normal text-sm leading-6 text-gray-500">
                Aloe Vera and Neem
              </p>
            </div>
          </a>

          <a
            href="javascript:;"
            className="mx-auto sm:ml-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500"
          >
            <div className="">
              <img
                src="https://pagedone.io/asset/uploads/1700726207.png"
                alt="perfume bottle image"
                className="w-full aspect-square"
              />
            </div>
            <div className="mt-5">
              <div className="flex items-center justify-between">
                <h6 className="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">
                  Cold Perfume
                </h6>
                <h6 className="font-semibold text-xl leading-8 text-indigo-600">
                  $100
                </h6>
              </div>
              <p className="mt-2 font-normal text-sm leading-6 text-gray-500">
                White perfume
              </p>
            </div>
          </a> */}
          </div>
        </div>
      )}
    </section>
  );
};
export default ProductsList;
