import about from "../assets/about-us.jpg";
const About = () => {
  return (
    <div className="py-16 max-w-7xl mx-auto">
      <h2 className="md:text-5xl text-4xl font-semibold tracking-tight text-center text-[#0a1b48]">
        About us
      </h2>

      <div id="about" className="relative bg-white overflow-hidden mt-16">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100"></polygon>
            </svg>

            <div className="pt-1"></div>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left mr-1">
                <h2 className="my-6 text-xl tracking-tight font-extrabold text-[#0a1b48] sm:text-2xl md:text-3xl">
                  Milano Group Technology
                </h2>

                <p className="text-[#0a1b48]">
                  Milano Group Technology, a cornerstone in Lebanon’s elevator
                  industry since 1990, has consistently met and exceeded the
                  expectations of a diverse clientele, including businesses,
                  private homes, villas, and shopping complexes. Our company is
                  distinguished by its bespoke elevator interiors that deliver
                  unparalleled comfort and safety to passengers. With a focus on
                  manufacturing a comprehensive range of elevator displays such
                  as TFT, LCD, and dot matrix LED indicators, Milano Group
                  Technology stays abreast of the latest technological trends.
                  We take pride in our post-sales maintenance services,
                  supported by a team of dedicated technical professionals,
                  including specialist engineers and technicians, all highly
                  skilled and experienced in their respective fields.
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={about}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default About;
