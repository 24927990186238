import { useState } from "react";
import classNames from "classnames";
import axios from "axios";
const Contact = () => {
  const [form, setForm] = useState([]);

  const [isNameErrorMessage, setIsNameErrorMessage] = useState(false);
  const [isEmailErrorMessage, setIsEmailErrorMessage] = useState(false);
  const [isMessageErrorMessage, setIsMessageErrorMessage] = useState(false);

  const handleInputForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  function containsOnlyLetters(str) {
    const regex = /^[a-zA-Z\s]{3,30}$/;
    return regex.test(str);
  }
  function isValidEmail(str) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(str);
  }
  function isValidMessage(str) {
    const regex = /.+/;
    return regex.test(str) && str.length < 1000;
  }
  const submit = () => {
    if (form.name === undefined || !containsOnlyLetters(form.name)) {
      setIsNameErrorMessage(true);
      return;
    } else {
      setIsNameErrorMessage(false);
    }
    if (!isValidEmail(form.email)) {
      setIsEmailErrorMessage(true);
      return;
    } else {
      setIsEmailErrorMessage(false);
    }
    if (
      form.message === null ||
      form.message === undefined ||
      !isValidMessage(form.message)
    ) {
      setIsMessageErrorMessage(true);
      return;
    } else {
      setIsMessageErrorMessage(false);
    }
    console.log(form);
    // callApi();
  };

  const callApi = async () => {
    try {
      const response = await axios.post("https://getform.io/f/paqgwgma", {
        data: form,
      });
      console.log("Response:", response.data);
    } catch (err) {
      console.error("Error:", err);
    }
  };
  return (
    <div className="container mt-12 mx-auto px-2 md:px-4">
      <section className="mx-5 md:mx-32">
        <div className="flex justify-center">
          <div className="text-center md:max-w-xl lg:max-w-3xl">
            <h2 className="mb-12 px-6 text-3xl font-bold text-[#0a1b48]">
              Contact us
            </h2>
          </div>
        </div>

        <div className="flex md:mt-6 justify-center items-center ">
          <div className="flex flex-col w-full justify-center items-center">
            <form className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:px-6 lg:w-5/12  ">
              <div className="mb-3 w-full">
                <label
                  className="block font-medium mb-[2px] text-[#0a1b48]"
                  htmlFor="exampleInput90"
                >
                  Name
                </label>
                <input
                  type="text"
                  className={classNames(
                    "px-2 py-2 border w-full outline-none rounded-md",
                    { "border-red-500": isNameErrorMessage }
                  )}
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={handleInputForm}
                  aria-required
                />
              </div>

              <div className="mb-3 w-full">
                <label
                  className="block font-medium mb-[2px] text-[#0a1b48]"
                  htmlFor="exampleInput90"
                >
                  Email
                </label>
                <input
                  type="email"
                  className={classNames(
                    "px-2 py-2 border w-full outline-none rounded-md",
                    { "border-red-500": isEmailErrorMessage }
                  )}
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={handleInputForm}
                  aria-required
                />
              </div>

              <div className="mb-3 w-full">
                <label
                  className="block font-medium mb-[2px] text-[#0a1b48]"
                  htmlFor="exampleInput90"
                >
                  Message
                </label>
                <textarea
                  className={classNames(
                    "px-2 py-2 border rounded-[5px] w-full outline-none",
                    { "border-red-500": isMessageErrorMessage }
                  )}
                  name="message"
                  id="message"
                  aria-required
                  onChange={handleInputForm}
                ></textarea>
              </div>

              <button
                type="button"
                onClick={submit}
                className="mb-6 inline-block w-full rounded bg-[#0a1b48] px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover:bg-[#0a1b96]"
              >
                Send
              </button>
            </form>
            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <div className="flex flex-wrap mt-12">
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-teal-400-100 p-4 text-[#0a1b96]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-[#0a1b48]">
                        Phone number
                      </p>
                      <p className="text-neutral-500 ">+961 76 12 56 89</p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-teal-400-100 p-4 text-[#0a1b96]">
                        <svg
                          width="32px"
                          height="32px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="🔍-Product-Icons"
                            stroke="none"
                            strokeWidth="2"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="ic_fluent_mail_48_regular"
                              fill="#0a1b96"
                              fillRule="nonzero"
                            >
                              <path
                                d="M18.875 4.5c1.45 0 2.625 1.175 2.625 2.625v9.75c0 1.45 -1.175 2.625 -2.625 2.625h-13.75A2.625 2.625 0 0 1 2.5 16.875v-9.75C2.5 5.675 3.675 4.5 5.125 4.5zm1.375 4.676 -7.949 4.372a0.625 0.625 0 0 1 -0.538 0.03l-0.064 -0.03L3.75 9.175V16.875a1.375 1.375 0 0 0 1.375 1.375h13.75a1.375 1.375 0 0 0 1.375 -1.375zM18.875 5.75h-13.75a1.375 1.375 0 0 0 -1.375 1.375v0.625L12 12.287l8.25 -4.537V7.125a1.375 1.375 0 0 0 -1.375 -1.375"
                                id="🎨-Color"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-[#0a1b48]">Email</p>
                      <p className="text-neutral-500 ">
                        contact@milanogtech.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
