import Commerce from "@chec/commerce.js";

const checAPIKey = "pk_56973377bb45aeee14c0dde8ad4c20db5889e0ebdf347";
const devEnvironment = process.env.NODE_ENV === "development";

const commerceConfig = {
  axiosConfig: {
    headers: {
      "X-Chec-Agent": "commerce.js/v2",
      "Chec-Version": "2021-09-29",
    },
  },
};

export default new Commerce(checAPIKey, devEnvironment, commerceConfig);
