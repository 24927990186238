import About from "../components/About";
import HomeBackgroundImage from "../components/HomeBackgroundImage";
import OurServices from "../components/OurServices";

const Home = () => {
  return (
    <div>
      <HomeBackgroundImage />
      <OurServices />
      <About />
    </div>
  );
};
export default Home;
