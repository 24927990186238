import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Layout from "./Layout";
import ComingSoon from "../pages/ComingSoon";
import Products from "../pages/Products";
import Contact from "../pages/Contact";
import Product from "../pages/Product";
import NoPage from "../pages/NoPage";
import Photos from "../pages/Photos";
class Routers extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="products" element={<Products />} />
            <Route path="contact" element={<Contact />} />
            <Route path="gallery/photos" element={<Photos />} />
            <Route path="product/:id" element={<Product />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}
export default Routers;
