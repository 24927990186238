import BackgroundImage from "../assets/background_image.jpg";
const HomeBackgroundImage = () => {
  return (
    // <div className="relative mx-auto w-full max-w-7xl">
    //   <div className=" absolute sm:mt-2 px-4 inset-0 flex justify-center items-center bg-gradient-to-br from-gray-700 to-gray-900">
    //     <div className="max-w-2xl flex flex-col justify-center items-center text-center">
    //       <h2 className="text-2xl sm:text-3xl md:text-4xl text-white font-extrabold tracking-wide">
    //         Dive into the world of Milano Group Tech
    //       </h2>

    //       <p className="mt-3 text-sm sm:text-base text-white font-medium">
    //         Your destination for innovative technology solutions!
    //       </p>

    //       <span className="mt-6 py-2.5 px-6 shadow rounded bg-indigo-500 text-sm sm:text-base text-white font-semibold hover:bg-indigo-600  hover:text-gray-900">
    //         Shop Now
    //       </span>
    //     </div>
    //   </div>
    //   <div>
    //     <img
    //       src={BackgroundImage}
    //       alt="backgroud image"
    //       className="relative inset-0 w-full  object-cover filter mix-blend-overlay"
    //     />
    //   </div>
    // </div>
    <div className="relative mx-auto w-full max-w-7xl h-screen ">
      <div className="absolute inset-0 flex justify-center items-center bg-gradient-to-br from-gray-700 to-gray-900">
        <div className="max-w-2xl flex flex-col justify-center items-center text-center">
          <h2 className="text-2xl sm:text-3xl md:text-4xl text-white font-extrabold tracking-wide">
            Dive into the world of Milano Group Tech
          </h2>

          <p className="mt-3 text-sm sm:text-base text-white font-medium">
            Your destination for innovative technology solutions!
          </p>

          {/* <span className="mt-6 py-2.5 px-6 shadow rounded bg-indigo-500 text-sm sm:text-base text-white font-semibold hover:bg-indigo-600 hover:text-gray-900">
            Shop Now
          </span> */}
        </div>
      </div>
      <div className="absolute inset-0">
        <img
          src={BackgroundImage}
          alt="background image"
          className="w-full h-full object-cover filter mix-blend-overlay"
        />
      </div>
    </div>
  );
};
export default HomeBackgroundImage;
