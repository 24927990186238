import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import commerce from "../lib/commerce";
import imageNotFound from "../assets/image-not-found.jpg";
const Product = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    fetchProductDetails();
  }, []);

  const fetchProductDetails = () => {
    commerce.products
      .retrieve(id)
      .then((product) => {
        setProduct(product);
        console.log(product);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/");
        console.log("There was an error fetching the products", error);
      });
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="bg-white  py-8 mt-10">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row -mx-4">
              <div className="md:flex-1 px-4">
                <div className="h-[460px] rounded-lg bg-white  mb-4">
                  {product.conditionals.has_images ? (
                    <img
                      src={product.image?.url}
                      alt={product.name}
                      className="w-full aspect-square object-cover"
                      // width={product.image.image_dimensions.width}
                      // height={product.image.image_dimensions.height}
                    />
                  ) : (
                    <img
                      src={imageNotFound}
                      alt={product.name}
                      className="w-full aspect-square object-cover"
                      // width={product.image.image_dimensions.width}
                      // height={product.image.image_dimensions.height}
                    />
                  )}
                  {/* <img
                    className="w-full h-full object-cover"
                    src="https://cdn.pixabay.com/photo/2020/05/22/17/53/mockup-5206355_960_720.jpg"
                    alt="Product Image"
                  /> */}
                </div>
                {/* <div className="flex -mx-2 mb-4">
              <div className="w-1/2 px-2">
                <button className="w-full bg-gray-900  text-white py-2 px-4 rounded-full font-bold hover:bg-gray-800 ">
                  Add to Cart
                </button>
              </div>
              <div className="w-1/2 px-2">
                <button className="w-full bg-gray-200  text-gray-800  py-2 px-4 rounded-full font-bold hover:bg-gray-300 ">
                  Add to Wishlist
                </button>
              </div>
            </div> */}
              </div>
              <div className="md:flex-1 px-4">
                <h2 className="text-4xl font-bold text-gray-800  mb-2">
                  {product.name}
                </h2>
                {/* <p className="text-gray-600  text-sm mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
              ante justo. Integer euismod libero id mauris malesuada tincidunt.
            </p> */}
                <div className="flex mb-4 ">
                  <div className="mr-4">
                    {/* <span className="font-bold text-gray-700 ">Price:</span> */}
                    <span className="text-gray-600 text-2xl mt-5 md:mt-0">
                      {product.price.formatted_with_symbol}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="font-bold text-gray-700 ">
                    Availability:
                  </span>
                  <span className="text-gray-600 ">In Stock</span>
                </div>
                {/* <div className="mb-4">
              <span className="font-bold text-gray-700 ">Select Color:</span>
              <div className="flex items-center mt-2">
                <button className="w-6 h-6 rounded-full bg-gray-800  mr-2"></button>
                <button className="w-6 h-6 rounded-full bg-red-500  mr-2"></button>
                <button className="w-6 h-6 rounded-full bg-blue-500  mr-2"></button>
                <button className="w-6 h-6 rounded-full bg-yellow-500  mr-2"></button>
              </div>
            </div> */}
                {/* <div className="mb-4">
              <span className="font-bold text-gray-700 ">Select Size:</span>
              <div className="flex items-center mt-2">
                <button className="bg-gray-300  text-gray-700  py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 ">
                  S
                </button>
                <button className="bg-gray-300  text-gray-700  py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 ">
                  M
                </button>
                <button className="bg-gray-300  text-gray-700  py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 ">
                  L
                </button>
                <button className="bg-gray-300  text-gray-700  py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 ">
                  XL
                </button>
                <button className="bg-gray-300  text-gray-700  py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 ">
                  XXL
                </button>
              </div>
            </div> */}
                <div className="md:mt-48 mt-10">
                  <span className="font-bold text-gray-700 ">
                    Product Description:
                  </span>
                  <p className="text-gray-600  text-sm mt-2">
                    {product.description.replace(/<\/?p>/g, "")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Product;
